import IFrame from './primitives/IFrame';
import whiteList from './constants';
import { Component } from 'react';
import { getInstance as getSocialInstance } from 'shared/services/Social';

import type { EmbedProvider } from './types';

import { RouteComponentProps } from 'react-router';

const social = getSocialInstance();

type Props = RouteComponentProps & {
  className?: string;
  height: number;
  html: string;
  provider: EmbedProvider;
  width: number;
};

class EmbedBlock extends Component<Props> {
  embed = React.createRef<HTMLIFrameElement>();

  componentDidMount() {
    // Unfortunatly we cannot set the src of the iFrame to the html prop, also not allowed to embed html, or use onLoad of iframe.
    if (this.embed.current?.contentWindow)
      this.embed.current.contentWindow.document.write(this.props.html);

    if (this.props.provider === 'Facebook') {
      social.isFacebookReady().then(social.parse.bind(social, 'fb'));
    }
  }

  shouldComponentUpdate({ html }: Props) {
    return html !== this.props.html;
  }

  addParamsToHTMLSource() {
    const { html, location } = this.props;

    const iframeUrlRegex = /(<iframe .*)src="(?<url>.*)"\s(.*<\/iframe>)/;

    try {
      const hostParams = new URLSearchParams(location?.search);

      const iframeSrcMatch = html.match(iframeUrlRegex);
      const iframeUrl = new URL(String(iframeSrcMatch?.groups?.url));

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, val] of hostParams.entries()) {
        iframeUrl.searchParams.append(key, val);
      }

      return html.replace(
        String(iframeSrcMatch?.groups?.url),
        iframeUrl.toString(),
      );
    } catch (e) {
      return html;
    }
  }

  render() {
    const { provider, className, height, width } = this.props;
    return whiteList.includes(provider) ? (
      <IFrame
        height={height}
        ref={this.embed}
        scrolling="no"
        title="embed"
        width={width}
      />
    ) : (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: this.addParamsToHTMLSource() }} // eslint-disable-line react/no-danger
      />
    );
  }
}

export default EmbedBlock;
