import Container from './primitives/Container';
import Eyes2Ears from '../Eyes2Ears';
import Featuring from 'shared/ui/components/Featuring';
import LiveE2E from '../Live';
import transport from 'api/transport';
import Truncate from 'components/Truncate';
import { get } from 'lodash-es';
import { getArtistUrl } from 'utils/url';
import { getLiveStationForArtist } from 'state/Artists/services';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

export default class ArtistE2E extends PureComponent {
  static propTypes = {
    ampUrl: PropTypes.string.isRequired,
    artist: PropTypes.object.isRequired,
    artistId: PropTypes.number.isRequired,
    requestArtist: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    const { artist, artistId, requestArtist, ampUrl } = this.props;
    if (!artist.length) requestArtist(artistId);
    transport(getLiveStationForArtist({ ampUrl, id: artistId })).then(
      ({ data }) => {
        const { recs = [] } = data;
        if (recs.length) {
          this.setState({ liveStationId: recs[0].stationId });
        }
      },
    );
  }

  render() {
    const { artistId, artist } = this.props;
    const { liveStationId } = this.state;
    const clampedTitle = <Truncate>{artist?.artistName ?? ''}</Truncate>;

    return (
      <Container>
        <Eyes2Ears
          stationId={artistId}
          stationType={STATION_TYPE.ARTIST}
          title={clampedTitle}
          url={getArtistUrl(artistId, get(artist, 'artistName')) || ''}
        >
          <span data-test="e2e-similar-artist" key={`e2e-similar-${artistId}`}>
            <Featuring artistId={artistId} />
          </span>
        </Eyes2Ears>
        {liveStationId ? <LiveE2E stationId={liveStationId} /> : null}
      </Container>
    );
  }
}
