import { PureComponent } from 'react';

class Parsely extends PureComponent {
  componentDidMount() {
    const { protocol } = window.location;

    const url =
      protocol === 'https:'
        ? 'd1z2jf7jlzjs58.cloudfront.net'
        : 'static.parsely.com';

    const script = document.createElement('script');
    script.id = 'parsely-script';
    script.async = true;
    script.src = `${protocol}//${url}/p.js`;

    this.root.appendChild(script);
  }

  render() {
    return (
      <div
        id="parsely-root"
        ref={root => {
          this.root = root;
        }}
        style={{ display: 'none' }}
      >
        <span data-parsely-site="www.iheart.com" id="parsely-cfg" />
      </div>
    );
  }
}

export default Parsely;
