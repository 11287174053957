import Eyes2Ears from '../Eyes2Ears';
import Featuring from 'shared/ui/components/Featuring';
import LiveE2E from '../Live';
import transport from 'api/transport';
import Truncate from 'components/Truncate';
import { getLiveStationForArtist } from 'state/Artists/services';
import { isEmpty } from 'lodash-es';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

export default class TrackE2E extends PureComponent {
  static propTypes = {
    ampUrl: PropTypes.string.isRequired,
    requestTracks: PropTypes.func.isRequired,
    track: PropTypes.object.isRequired,
    trackId: PropTypes.number.isRequired,
  };

  state = {};

  componentDidMount() {
    const { track, trackId, requestTracks } = this.props;
    if (!track || isEmpty(track)) requestTracks(trackId);
  }

  componentDidUpdate(prevProps) {
    const { track, ampUrl } = this.props;
    if (track?.artistId && track?.id !== prevProps?.track?.id) {
      transport(getLiveStationForArtist({ ampUrl, id: track.artistId })).then(
        ({ data }) => {
          const { recs = [] } = data;
          this.setState({ liveStationId: recs[0].stationId });
        },
      );
    }
  }

  render() {
    const { trackId, track } = this.props;
    const { liveStationId } = this.state;

    const trackE2EartistId = track?.artistId ?? '';
    const clampedTitle = <Truncate>{track?.title ?? ''}</Truncate>;

    return (
      <div css={{ display: 'flex', flexWrap: 'wrap' }}>
        <Eyes2Ears
          stationId={trackId}
          stationType={STATION_TYPE.TRACK}
          title={clampedTitle}
          trackId={trackId}
          url={track?.url ?? ''}
        >
          <span
            data-test="e2e-similar-track"
            key={`e2e-similar-${trackE2EartistId}`}
          >
            <Featuring artistId={trackE2EartistId} />
          </span>
        </Eyes2Ears>
        {liveStationId ? <LiveE2E stationId={liveStationId} /> : null}
      </div>
    );
  }
}
