import Label from './primitives/Label';
import Link from './primitives/Link';

function ArticleTopics({ topics }) {
  return topics.length ? (
    <div>
      <Label>Topics</Label>
      {topics.map(topic => (
        <Link key={`topic-${topic.name}-${topic.id}`} to={topic.url}>
          {topic.name}
        </Link>
      ))}
    </div>
  ) : null;
}

ArticleTopics.propTypes = { topics: PropTypes.array.isRequired };

export default ArticleTopics;
